import React, { useContext, useEffect } from 'react';
import { BrowserRouter, Switch, Route, useParams } from 'react-router-dom';

import { uxTracking } from './util/ux-tracking';
import Review from './containers/Review';
import Referral from './containers/Referral';
import { AppContext } from './containers/AppContext';
import HtmlHead from './components/UI/Head/HtmlHead';
import RepairyReview from './containers/RepairyReview';
import ReferralRedeem from './containers/ReferralRedeem';
import ReviewExample from './components/Review/ReviewExample';
import RedirectingToBookingFeedback from './feedback-redirect';
import { DashBookingDetail } from './components/BookingRequest/BookingRequest';
import { ShortCodeBooking } from './components/ShortCodeBooking/ShortCodeBooking';
import NotificationUnsubs, {
  UnsubscribeDone
} from './components/NotificationUnsubs';
import {
  AppName,
  referralPrefix,
  RepairyBookingURL,
  referralRedeemPrefix,
} from './util/lib';

import css from './App.module.css';

function App() {
  const { theme } = useContext(AppContext);

  const updateHeight = async () => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    // We listen to the resize event
    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  };
  useEffect(() => {
    updateHeight();
    uxTracking.init();
  }, []);

  if (AppName && AppName.toLowerCase() === 'flytree') {
    return (
      <BrowserRouter>
        <div
          style={theme}
          className={css.App}>
          <HtmlHead flytree />
          <Switch>
            <Route
              path="/"
              exact
              component={() => {
                window.location.href = 'https://www.flytree.co';
                return null;
              }}
            >
            </Route>
            <Route path={[
              `${referralPrefix}/:code/share/:refCode`,
              `${referralPrefix}/:code`,
            ]}>
              <Referral />
            </Route>
            <Route path={`${referralRedeemPrefix}/:code`}>
              <ReferralRedeem />
            </Route>
            <Route exact path='/1234567890'>
              <ReviewExample />
            </Route>
            <Route path="/:code">
              <Review />
            </Route>
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
  return (
    <BrowserRouter>
      <div className={css.App}>
        <HtmlHead repairy />
        <Switch>
          <Route
            path="/"
            exact
            component={() => {
              window.location.href = 'https://www.repairy.com.au/';
              return null;
            }}
          ></Route>
          <Route path="/i/:id/:code">
            <DashBookingDetail prefix="details" />
          </Route>
          <Route path="/d/:id/:code">
            <DashBookingDetail prefix="details" />
          </Route>
          <Route path="/br/:id/:code">
            <DashBookingDetail prefix="requests" />
          </Route>
          <Route path="/s/:id">
            <RedirectingToBookingConfirmation />
          </Route>
          <Route path="/r/:code">
            <RepairyReview />
          </Route>
          <Route path="/f/:code">
            <RedirectingToBookingFeedback />
          </Route>
          <Route path="/off/:id">
            <NotificationUnsubs />
          </Route>
          <Route path="/unsubscribed">
            <UnsubscribeDone />
          </Route>
          <Route path="/:id/:code">
            <ShortCodeBooking />
          </Route>
          <Route path="/:id">
            <RedirectingToBooking />
          </Route>
        </Switch>
      </div>
    </BrowserRouter>
  );
}

function RedirectingToBooking() {
  const { id, code } = useParams();
  const uris = [RepairyBookingURL, '/', id];
  if (code) {
    uris.push(...['/', code]);
  }
  window.location.href = uris.join('');
  return (
    <>
    <HtmlHead repairy />
    Redirecting...
    </>
  );
}

function RedirectingToBookingConfirmation() {
  const { id } = useParams();
  window.location.href = [RepairyBookingURL, '/s/', id].join('');
  return (
    <>
    <HtmlHead repairy />
    Redirecting...
    </>
  );
}

export default App;
