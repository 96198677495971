import { useEffect, useState } from 'react';

import {
  useApi,
  RepairyBookingURL,
  RepairyBookingV2URL,
  RepairyDashboardURL,
  RepairyDashboardV2URL,
} from '../util/lib';

const getBaseURL = (context, version) => {
  if (context === 'dashboard') {
    if (version === 'v2') return RepairyDashboardV2URL;
    return RepairyDashboardURL;
  }
  // default is booking base url
  if (version === 'v2') return RepairyBookingV2URL;
  return RepairyBookingURL;
}

export const useRedirectWithConfigVersion = ({ id, context, uris }) => {
  const [pristine, setPristine] = useState(true);
  const [error, setError] = useState();
	const api = useApi();
  useEffect(() => {
    const checkVersion = async () => {
      try {
        if (!pristine || !uris) return;
        setPristine(false);
        // fetch config
        const response = await api({
          method: 'get',
          responseType: 'json',
          url: `/sdk/${id}/config-version`,
        });
        // set baseURL
        const version = response.data && response.data.configVersion;
        const baseURL = getBaseURL(context, version);
        // redirect
        const url = [
          baseURL,
          ...uris
        ];
        window.location.href = url.join('/');
      } catch (error) {
        console.error(error);
        setError(error);
      }
    }
    checkVersion();
  }, [api, context, id, pristine, uris]);

  return {
    error,
  }
}